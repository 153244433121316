import { Button, CircularProgress, TextField } from "@mui/material";
import Head from "next/head";
import Image from "next/legacy/image";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useState } from "react";

import styles from "@/styles/Login/Login.module.scss";

const LoginPage = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loginInProgress, setLoginInProgress] = useState(false);
    const [loginTakingLong, setLoginTakingLong] = useState(false);
    const [validateFields, setValidateFields] = useState(false);

    const { t } = useTranslation(["common", "login"]);
    const router = useRouter();

    const isAbleToLogin = email.length > 0 && password.length > 0;
    const login = async () => {
        if (isAbleToLogin) {
            setLoginInProgress(true);
            const timeout = setTimeout(() => {
                setLoginTakingLong(true);
            }, 5000);

            const login_data = JSON.stringify({
                email: email,
                password: password,
            });

            await fetch("/api/auth/login/", {
                method: "POST",
                body: login_data,
            })
                .then((res) => {
                    return res.json();
                })
                .then((response) => {
                    clearTimeout(timeout);
                    setLoginTakingLong(false);
                    if (response?.response?.token) {
                        router.push("/");
                        return true;
                    } else {
                        setLoginInProgress(false);
                        return false;
                    }
                });
        }
    };

    return (
        <>
            <Head>
                <title>{t("login:meta.title")}</title>
            </Head>
            <div className={styles.login_page_holder}>
                <div className={styles.login_container}>
                    <div className={styles.form_holder}>
                        {!loginInProgress ? (
                            <>
                                <h1
                                    dangerouslySetInnerHTML={{
                                        __html: t("login:page.title"),
                                    }}
                                />
                                <TextField
                                    label={t("common:form.email")}
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                    }}
                                    size={"small"}
                                    className={styles.text_field}
                                    error={email.length == 0 && validateFields}
                                    onKeyUp={(e: any) => {
                                        if (e.keyCode == 13) {
                                            setValidateFields(true);
                                            login();
                                        }
                                    }}
                                />
                                <TextField
                                    type={"password"}
                                    label={t("common:form.password")}
                                    value={password}
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                    }}
                                    size={"small"}
                                    className={styles.text_field}
                                    error={
                                        password.length == 0 && validateFields
                                    }
                                    onKeyUp={(e: any) => {
                                        if (e.keyCode == 13) {
                                            setValidateFields(true);
                                            login();
                                        }
                                    }}
                                />
                                <Button
                                    disabled={!isAbleToLogin}
                                    variant="contained"
                                    className={styles.login_button}
                                    onClick={() => {
                                        login();
                                    }}
                                >
                                    {t("login:page.actions.signIn")}
                                </Button>
                            </>
                        ) : (
                            <>
                                <CircularProgress />
                                {loginTakingLong && (
                                    <div style={{ marginTop: "10px" }}>
                                        Taking long? <a href="">Refresh</a>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                    <div className={styles.brand_holder}>
                        <div className={styles.logo_holder}>
                            <Image
                                src={`/logo/performansion_logo.png`}
                                layout="fill"
                                objectFit="contain"
                                objectPosition="center center"
                                alt={`Performansion logo`}
                                priority={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LoginPage;

export async function getStaticProps({ locale }: any) {
    return {
        props: {
            ...(await serverSideTranslations(locale, ["common", "login"])),
        },
    };
}
